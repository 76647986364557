import { Button } from "@fefyi/ui/button";
import { Tile } from "./tile";
import { MotionLogo } from "@fefyi/ui/astro/icons/motion-logo";
import { ArrowRight } from "lucide-react";
import { twMerge } from "tailwind-merge";

export const SubnavCourses = () => (
  <>
    <div className="grid grid-cols-1 gap-2 md:hidden">
      <Tile
        title="Motion for React"
        subtitle=""
        href="/course/motion"
        icon={({ className }: { className: string }) => (
          <MotionLogo className={twMerge(className, "grid bg-white")} />
        )}
      />
      <Tile
        icon={ArrowRight}
        className="border-white-opaque bg-white-opaque mr-auto border px-4 md:hidden [&_svg]:w-4"
        title="View all"
        href="/courses"
      />
    </div>
    <div className="grid grid-cols-1 gap-3 max-lg:w-[300px] max-md:hidden lg:grid-cols-[repeat(2,300px)]">
      <div>
        <a
          href="/course/motion"
          className="block rounded-2xl ring-white outline-hidden focus-visible:ring-2"
        >
          <img
            src="/course/static/og-motion-for-react.jpg"
            alt="Motion for React Course"
            width={600}
            height={315}
            className="h-auto w-full rounded-2xl"
          />
        </a>
      </div>
      <div className="bg-white-opaque border-white-opaque my-auto h-full rounded-2xl border p-3">
        <a
          href="/course/motion"
          className="group mb-3 block rounded-md text-lg leading-tight outline-hidden"
        >
          <strong className="text-xl group-focus-visible:underline">
            Latest course
          </strong>
          <br />
          <span className="block [white-space:balance]">
            Crafting beautiful experiences with Motion for React
          </span>
        </a>
        <div className="flex gap-2 max-md:justify-center">
          <Button href="/course/motion" size="small">
            Go to course
          </Button>
          <Button
            href="https://www.fantasticmotion.fyi"
            target="_blank"
            size="small"
            intent="white"
          >
            Learn more
          </Button>
        </div>
      </div>
    </div>
  </>
);
