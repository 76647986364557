import type { LucideIcon } from "lucide-react";
import { twMerge } from "tailwind-merge";

type Props = {
  title: string;
  subtitle?: string;
  icon?: React.ComponentType<{ className: string }> | LucideIcon;
  href: string;
  className?: string;
};

export const Tile = ({
  title,
  subtitle,
  icon: Icon,
  href,
  className,
}: Props) => (
  <a
    href={href}
    className={twMerge(
      "group/anchor flex rounded-2xl text-lg text-white outline-hidden transition-colors max-md:items-center max-md:gap-3 max-md:py-2 md:flex-col md:border md:border-white-opaque md:bg-white-opaque md:p-5 md:hover:bg-white-opaque-dark md:focus-visible:bg-white-opaque-dark lg:text-xl",
      className,
    )}
  >
    {Icon && (
      <Icon className="relative block h-5 w-5 md:mb-auto md:h-8 md:w-8" />
    )}

    <span className="flex flex-col">
      <span className="title block leading-tight md:mt-3 md:font-bold">
        {title}
      </span>
      {subtitle && (
        <span className="block text-[12px] leading-snug">{subtitle}</span>
      )}
    </span>
  </a>
);
